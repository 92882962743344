<template>
    <div class="box">
        <h2>
            Prozess konfigurieren
            <small>{{ process.label }}</small>
        </h2>
        <div class="body">
            <div class="graph">
                <template v-for="(lane, index) in process.graph">
                    <div @click="selectLane(index)" class="lane-title" v-bind:key="'lt_' + index">
                        <span class="lane-title">{{ lane.label }}</span>
                    </div>
                    <div class="process" v-if="selectedLane == index" v-bind:key="'lp_' + index">
                        <process-step :step="lane.start" :selStep="selectedStep" :selEvent="selectedEvent.id" @select-step="selectStep($event)" @select-event="selectEvent($event)"></process-step>
                    </div>
                </template>
            </div>

            <div v-if="selectedStep != ''">
                <h3>Dokumente</h3>
                <div v-for="area in docAreas" v-bind:key="area.id">
                    <h4>{{ area.label }}</h4>
                    <table>
                        <tbody>
                            <tr v-for="(doc, index) in area.docs" v-bind:key="index">
                                <td class="move" v-if="!area.isFixed">
                                    <div>
                                        <span @click="moveUp(index, area)" v-show="index != 0">
                                            <i class="fal fa-chevron-up"></i>
                                        </span>
                                        <span @click="moveDown(index, area)" v-show="index != area.docs.length - 1">
                                            <i class="fal fa-chevron-down"></i>
                                        </span>
                                    </div>
                                </td>
                                <td v-if="area.isFixed" style="width: 15%">{{ doc.name }}</td>
                                <td>
                                    <div class="form-group form-float no-error">
                                        <input v-model="doc.label" :id="'doc_label_' + area.id + '_' + index" placeholder=" " type="text" />
                                        <label :for="'doc_label_' + area.id + '_' + index">Anzeige</label>
                                    </div>
                                </td>
                                <td style="width: 20%">
                                    <div class="form-group form-float no-error">
                                        <input v-model="doc.filename" :id="'doc_filename_' + area.id + '_' + index" placeholder=" " type="text" />
                                        <label :for="'doc_filename_' + area.id + '_' + index">Dateiname</label>
                                    </div>
                                </td>
                                <td style="width: 20%">
                                    <div class="form-group form-float form-select no-error">
                                        <select placeholder=" " v-model="doc.combined" :id="'doc_combined' + area.id + '_' + index">
                                            <option value></option>
                                            <optgroup label="Latex Current">
                                                <option v-for="tex in latexDocs.current" v-bind:key="tex" v-bind:value="'latex.current::' + tex">{{ tex }}</option>
                                            </optgroup>
                                            <optgroup label="Latex Legacy">
                                                <option v-for="tex in latexDocs.legacy" v-bind:key="tex" v-bind:value="'latex.legacy::' + tex">{{ tex }}</option>
                                            </optgroup>
                                            <optgroup label="PDF Forms">
                                                <option v-for="form in pdfForms" v-bind:key="form.id" v-bind:value="'generator.' + form.id">{{ form.label }}</option>
                                            </optgroup>
                                            <optgroup label="Statische Dokumente">
                                                <option v-for="staticDoc in staticDocs" v-bind:key="staticDoc.id" v-bind:value="'static.' + staticDoc.id">{{ staticDoc.name }}</option>
                                            </optgroup>
                                        </select>
                                        <label :for="'doc_combined' + area.id + '_' + index">Generator</label>
                                    </div>
                                </td>
                                <td class="remove" v-if="!area.isFixed">
                                    <i @click="removeDoc(index, area)" class="delete fal fa-trash-alt"></i>
                                </td>
                            </tr>
                            <tr v-if="!area.isFixed">
                                <td colspan="5">
                                    <span class="add" @click="addDoc(area)"> <i class="add fal fa-plus-circle"></i> Hinzufügen </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr />

                <div class="button-row">
                    <button type="button" @click.prevent="saveDocs()" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </div>
            <div v-if="selectedEvent.id">
                <h3>E-Mails</h3>
                <h4>{{ selectedEvent.label }}</h4>
                <table class="emails">
                    <thead>
                        <tr>
                            <th>Empfänger</th>
                            <th style="width: 20%">Template</th>
                            <th style="width: 35%">Anhänge</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(email, index) in emails" v-bind:key="index">
                            <td>
                                <vue-tags-input
                                    placeholder="Empfänger hinzufügen"
                                    v-model="email.recipient"
                                    :tags="email.recipients"
                                    @tags-changed="newTags => newRecipients(index, newTags)"
                                    :validation="tagValidation"
                                    @before-adding-tag="beforeAddRecipient($event)"
                                    :autocomplete-items="autocompleteRecipients"
                                />
                            </td>
                            <td style="width: 20%">
                                <div class="form-group form-float form-select no-error">
                                    <select placeholder=" " v-model="email.template" :id="'email_template_' + index">
                                        <option value></option>
                                        <option v-for="template in emailTemplates" v-bind:key="template.id" v-bind:value="template.id">{{ template.label }}</option>
                                    </select>
                                </div>
                            </td>
                            <td class="subtable">
                                <table>
                                    <tbody>
                                        <tr v-for="(attachment, aidx) in email.attachments" v-bind:key="aidx">
                                            <td>
                                                <div class="form-group form-float no-error">
                                                    <input v-model="attachment.filename" :id="'attachment_filename_' + index + '_' + aidx" placeholder=" " type="text" />
                                                    <label :for="'attachment_filename_' + index + '_' + aidx">Dateiname</label>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group form-float form-select no-error">
                                                    <select placeholder=" " v-model="attachment.combined" :id="'attachment_combined_' + index + '_' + aidx">
                                                        <option value></option>
                                                        <optgroup label="Latex Current">
                                                            <option v-for="tex in latexDocs.current" v-bind:key="tex" v-bind:value="'latex.current::' + tex">{{ tex }}</option>
                                                        </optgroup>
                                                        <optgroup label="Latex Legacy">
                                                            <option v-for="tex in latexDocs.legacy" v-bind:key="tex" v-bind:value="'latex.legacy::' + tex">{{ tex }}</option>
                                                        </optgroup>
                                                        <optgroup label="PDF Forms">
                                                            <option v-for="form in pdfForms" v-bind:key="form.id" v-bind:value="'generator.' + form.id">{{ form.label }}</option>
                                                        </optgroup>
                                                    </select>
                                                    <label :for="'attachment_combined_' + index + '_' + aidx">Generator</label>
                                                </div>
                                            </td>
                                            <td class="remove">
                                                <i @click="removeAttachment(index, aidx)" class="delete fal fa-trash-alt"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">
                                                <span class="add" @click="addAttachment(index)"> <i class="add fal fa-plus-circle"></i> Hinzufügen </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="remove">
                                <i @click="removeEmail(index)" class="delete fal fa-trash-alt"></i>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <span class="add" @click="addEmail()"> <i class="add fal fa-plus-circle"></i> Hinzufügen </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr />

                <div class="button-row">
                    <button type="button" @click.prevent="saveEmails()" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProcessStep from "../../components/docs/Step.vue";
import Vue from "vue";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
    data() {
        return {
            selectedLane: 0,
            selectedStep: "",
            selectedEvent: {},
            process: {},
            docAreas: [],
            emails: [],
            latexDocs: {
                current: [],
                legacy: []
            },
            staticDocs: [],
            pdfForms: [],
            tagValidation: [
                {
                    classes: "prefix",
                    rule: /^(To:|Cc:|Bcc:)/
                }
            ],
            autocompleteRecipients: [{ text: "To:antragsteller" }, { text: "Cc:vermittler" }]
        };
    },
    computed: {
        apiBase() {
            return "/docs/process/" + this.$route.params.id;
        },
        product() {
            var parts = this.$route.params.id.split(".");

            return parts[0] + "." + parts[1];
        },
        emailTemplates() {
            let elements = {};
            for (let e = 0; e < this.emails.length; e++) {
                elements[this.emails[e].template] = {
                    id: this.emails[e].template,
                    label: this.emails[e].template
                };
            }

            elements["default"] = {
                id: "default",
                label: "default"
            };

            return Object.values(elements);
        }
    },
    created() {
        this.fetchData();
    },
    mounted() {
        var sel = location.hash.substring(1);
        var parts = sel.split(";");
        if (parts.length != 2) {
            return;
        }
        var laneP = parts[0].split("=");
        var stepP = parts[1].split("=");
        if (laneP.length != 2 || stepP.length != 2 || laneP[0] != "lane" || (stepP[0] != "step" && stepP[0] != "event")) {
            return;
        }
        this.selectLane(laneP[1]);
        if (stepP[0] == "step") {
            this.selectStep(stepP[1]);
        } else {
            this.selectEvent({ id: stepP[1] });
        }
    },
    methods: {
        addDoc(area) {
            area.docs.push({ label: "", filename: "", latex: "", generator: "", combined: "" });
        },
        selectLane(id) {
            if (this.selectedLane !== id) {
                this.selectedStep = "";
                this.selectedEvent = {};
            }
            this.selectedLane = id;
        },
        selectStep(id) {
            this.selectedStep = id;
        },
        selectEvent(event) {
            this.selectedEvent = event;
        },
        moveUp(index, area) {
            this.swapRules(index - 1, index, area);
        },
        moveDown(index, area) {
            this.swapRules(index + 1, index, area);
        },
        swapRules(x, y, area) {
            var tmp = area.docs[x];
            area.docs[x] = area.docs[y];
            Vue.set(area.docs, y, tmp);
        },
        removeDoc(index, area) {
            area.docs.splice(index, 1);
        },
        addEmail() {
            this.emails.push({ id: "", to: [], cc: [], bcc: [], recipients: [], template: "", attachments: [] });
        },
        removeEmail(index) {
            this.emails.splice(index, 1);
        },
        addAttachment(index) {
            this.emails[index].attachments.push({ filename: "", latex: "", generator: "", combined: "" });
        },
        removeAttachment(index, aidx) {
            this.emails[index].attachments.splice(aidx, 1);
        },
        newRecipients(index, tags) {
            this.emails[index].recipients = tags;
            this.emails[index].recipient = "";
        },
        beforeAddRecipient(data) {
            if (data.tag.tiClasses.indexOf("ti-invalid") > -1) {
                // Invalid tag, do not add
                this.$snotify.error("Ungültiger Empfänger. Prefix 'To:', 'Cc:' oder 'Bcc:' nötig");
            } else {
                data.addTag();
                return true;
            }

            return false;
        },
        saveDocs() {
            var areas = [];
            for (var a = 0; a < this.docAreas.length; a++) {
                var docs = [];
                for (var d = 0; d < this.docAreas[a].docs.length; d++) {
                    var generator = null;
                    var latex = null;
                    var staticDoc = null;

                    if (this.docAreas[a].docs[d].combined.indexOf("latex.") === 0) {
                        latex = this.docAreas[a].docs[d].combined.substring(6);
                        this.docAreas[a].docs[d].latex = latex;
                    } else if (this.docAreas[a].docs[d].combined.indexOf("generator.") === 0) {
                        generator = this.docAreas[a].docs[d].combined.substring(10);
                        this.docAreas[a].docs[d].generator = generator;
                    } else if (this.docAreas[a].docs[d].combined.indexOf("static.") === 0) {
                        staticDoc = this.docAreas[a].docs[d].combined.substring(7);
                        this.docAreas[a].docs[d].static = generator;
                    }

                    docs.push({
                        fixedid: this.docAreas[a].docs[d].fixedid,
                        label: this.docAreas[a].docs[d].label,
                        filename: this.docAreas[a].docs[d].filename,
                        latex: latex,
                        generator: generator,
                        static: staticDoc
                    });
                }

                areas.push({ id: this.docAreas[a].id, docs: docs });
            }

            this.$api.put(this.apiBase + "/areas/" + this.selectedStep, areas).then(
                () => {
                    // Template created
                    this.$snotify.success("Zuordnung aktualisiert");
                },
                () => {
                    // Update failed
                    this.$snotify.error("Aktualisierung fehlgeschlagen");
                }
            );
        },
        saveEmails() {
            var emails = [];

            for (var e = 0; e < this.emails.length; e++) {
                var to = [];
                var cc = [];
                var bcc = [];
                var attachments = [];

                for (var r = 0; r < this.emails[e].recipients.length; r++) {
                    var recipient = this.emails[e].recipients[r].text;
                    if (typeof recipient === "undefined") {
                        continue;
                    }
                    if (recipient.indexOf("To:") === 0) {
                        to.push(recipient.substring(3));
                    } else if (recipient.indexOf("Cc:") === 0) {
                        cc.push(recipient.substring(3));
                    } else if (recipient.indexOf("Bcc:") === 0) {
                        bcc.push(recipient.substring(4));
                    }
                }
                this.emails[e].to = to;
                this.emails[e].cc = cc;
                this.emails[bcc] = bcc;

                for (var a = 0; a < this.emails[e].attachments.length; a++) {
                    var latex = null;
                    var generator = null;

                    if (this.emails[e].attachments[a].combined.indexOf("latex.") === 0) {
                        latex = this.emails[e].attachments[a].combined.substring(6);
                        this.emails[e].attachments[a].latex = latex;
                    } else if (this.emails[e].attachments[a].combined.indexOf("generator.") === 0) {
                        generator = this.emails[e].attachments[a].combined.substring(10);
                        this.emails[e].attachments[a].generator = generator;
                    }

                    attachments.push({
                        filename: this.emails[e].attachments[a].filename,
                        latex: latex,
                        generator: generator
                    });
                }

                emails.push({
                    template: this.emails[e].template,
                    to: to,
                    cc: cc,
                    bcc: bcc,
                    attachments: attachments
                });
            }

            this.$api.put(this.apiBase + "/emails/" + this.selectedEvent.id, emails).then(
                () => {
                    // Template created
                    this.$snotify.success("E-Mails aktualisiert");
                },
                () => {
                    // Update failed
                    this.$snotify.error("Aktualisierung fehlgeschlagen");
                }
            );
        },
        fetchData() {
            this.fetchProcess();
            this.fetchPdfForms();
            this.fetchLatexDocs();
            this.fetchStaticDocs();
        },
        fetchProcess() {
            this.$api.get(this.apiBase).then(
                response => {
                    this.process = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden des Prozesses");
                }
            );
        },
        fetchPdfForms() {
            let special = "";
            if (this.product.lastIndexOf("corporate.", 0) === 0) {
                // KTV select
                special = "/corporate.select";
            }

            this.$api.get("/docs/category/" + this.product + "/pdfforms" + special).then(
                response => {
                    this.pdfForms = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der PDF Formulare");
                }
            );
        },
        fetchLatexDocs() {
            this.$api.get("/docs/latex").then(
                response => {
                    this.latexDocs = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der LaTeX Dokumente");
                }
            );
        },
        fetchStaticDocs() {
            this.$api.get("/docs/static").then(
                response => {
                    this.staticDocs = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Statischen Dokumente");
                }
            );
        },
        fetchDocAreas() {
            this.$api.get(this.apiBase + "/areas/" + this.selectedStep).then(
                response => {
                    // create "combined" element for selected document
                    for (var a = 0; a < response.data.data.length; a++) {
                        if (response.data.data[a].docs === null) {
                            response.data.data[a].docs = [];
                        }
                        for (var d = 0; d < response.data.data[a].docs.length; d++) {
                            response.data.data[a].docs[d].combined = "";
                            if (response.data.data[a].docs[d].latex) {
                                response.data.data[a].docs[d].combined = "latex." + response.data.data[a].docs[d].latex;
                            } else if (response.data.data[a].docs[d].generator) {
                                response.data.data[a].docs[d].combined = "generator." + response.data.data[a].docs[d].generator;
                            } else if (response.data.data[a].docs[d].static) {
                                response.data.data[a].docs[d].combined = "static." + response.data.data[a].docs[d].static;
                            }
                        }
                    }

                    this.docAreas = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Dokumentenbereiche");
                }
            );
        },
        fetchEmails() {
            this.$api.get(this.apiBase + "/emails/" + this.selectedEvent.id).then(
                response => {
                    for (var e = 0; e < response.data.data.length; e++) {
                        // create "combined" element for selected attachment document
                        if (response.data.data[e].attachments !== null) {
                            for (var a = 0; a < response.data.data[e].attachments.length; a++) {
                                response.data.data[e].attachments[a].combined = "";
                                if (response.data.data[e].attachments[a].latex) {
                                    response.data.data[e].attachments[a].combined = "latex." + response.data.data[e].attachments[a].latex;
                                } else if (response.data.data[e].attachments[a].generator) {
                                    response.data.data[e].attachments[a].combined = "generator." + response.data.data[e].attachments[a].generator;
                                }
                            }
                        } else {
                            response.data.data[e].attachments = [];
                        }

                        // Recipients
                        if (response.data.data[e].cc === null) {
                            response.data.data[e].cc = [];
                        }
                        if (response.data.data[e].bcc === null) {
                            response.data.data[e].bcc = [];
                        }

                        response.data.data[e].recipients = [];
                        response.data.data[e].recipient = "";
                        for (var r = 0; r < response.data.data[e].to.length; r++) {
                            response.data.data[e].recipients.push({ text: "To:" + response.data.data[e].to[r] });
                        }
                        for (r = 0; r < response.data.data[e].cc.length; r++) {
                            response.data.data[e].recipients.push({ text: "Cc:" + response.data.data[e].cc[r] });
                        }
                        for (r = 0; r < response.data.data[e].bcc.length; r++) {
                            response.data.data[e].recipients.push({ text: "Bcc:" + response.data.data[e].bcc[r] });
                        }
                    }

                    this.emails = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der E-Mails");
                }
            );
        }
    },
    watch: {
        selectedStep: function(newValue) {
            if (newValue !== null && newValue !== "") {
                this.fetchDocAreas();
            }
        },
        selectedEvent: function(newValue) {
            if (newValue !== null && newValue.id !== "" && typeof newValue.id !== "undefined") {
                this.fetchEmails();
            }
        }
    },
    components: {
        ProcessStep,
        VueTagsInput
    }
};
</script>

<style lang="scss" scoped>
div.graph {
    display: flex;
    border: 1px solid #e2dee6;
    border-right: 0;
    min-height: 20rem;

    div {
        display: flex;
    }

    div.process {
        overflow-x: auto;
        padding: 1rem;
        border-right: 1px solid #e2dee6;
        flex-grow: 1;
    }

    div.lane-title {
        border-right: 1px solid #e2dee6;
        position: relative;
        width: 2rem;
        cursor: pointer;
    }
    span.lane-title {
        line-height: 2rem;
        position: absolute;
        left: 2rem;
        top: 0rem;
        padding-left: 0.5rem;
        width: 100%;
        transform-origin: 0 0;
        transform: rotate(90deg);
    }
}

td.move {
    padding: 0;

    div {
        display: flex;
        flex-direction: column;
        font-size: 0.7rem;
        padding: 0;
        align-items: center;

        span {
            cursor: pointer;
        }
    }
}

td.remove {
    width: 2rem;
}

table.emails {
    td {
        vertical-align: top;

        table td {
            border-top: 0;
        }

        &.remove {
            vertical-align: middle;
            border-left: 1px solid #e5ebec;
        }

        &.subtable {
            padding: 0;
        }
    }
}
</style>
<style lang="scss">
.vue-tags-input {
    max-width: 100% !important; // Important is needed as the max width is set scoped and therefore has precedence

    .ti-input {
        border: 1px solid #bbbbbb;
        border-radius: 3px;
        padding: 0;
        min-height: 2.4rem;
    }
}
</style>
